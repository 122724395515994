import React from 'react';
import { useLocation } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CityLanguageSelector({ current }) {
  const location = useLocation();
  const currentPathLanguage = location.pathname.split('/')[1];
  current = currentPathLanguage;
  return (
    <>
    {location.pathname.split('/')[1] === 'en' ? (
      <div className="dropdown is-hoverable">
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
          id="lang-button"
        >
          <span className="icon">
            <FontAwesomeIcon icon="language" />
          </span>
          <span>
            {' '}
            {' - '}
            English
          </span>
          <span className="icon is-small">
            <FontAwesomeIcon icon="angle-down" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu6" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <a href="/es/fountain-hills/">Español</a>
          </div>
        </div>
      </div>
    </div>
    ) : (
      <div className="dropdown is-hoverable">
      <div className="dropdown-trigger">
        <button
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu6"
          id="lang-button"
        >
          <span className="icon">
            <FontAwesomeIcon icon="language" />
          </span>
          <span>
            {' '}
            {' - '}
            Español
          </span>
          <span className="icon is-small">
            <FontAwesomeIcon icon="angle-down" />
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu6" role="menu">
        <div className="dropdown-content">
          <div className="dropdown-item">
            <a href="/en/fountain-hills/">English</a>
          </div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default CityLanguageSelector;
